.banner-maincontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.banner-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.banner-text {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #424242;
    position: relative;
    margin-left: 20px;

}

.banner-text-sm {
    display: none;
}

.banner-subtext {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #424242;
    position: relative;
    margin-left: 5px;
    font-style: italic;

}

.logo {
    margin-left: 120px;
    width: 60px;
    height: 55px;
}

.logo:hover {
    cursor: pointer;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
}

@media screen and (max-width: 768px) {
    .banner-maincontainer {
        display: none;
        height: 80px;
    }

    .banner-text-sm {
        display: block;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: #424242;
        position: relative;
        margin-left: 20px;
    }
}