.menu-container {
    background-color: white;
    padding: 10px;
}

.menu-header {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.back-arrow {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
}

.menu-items {
    margin-top: 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
}

.menu-row {
    margin-bottom: 10px;
    text-align: center;
}

.menu-title {
    display: inline-block;
    position: relative;
}
.menu-title:hover {
    cursor: pointer;
}
.menu-line {

    width: 100%;
    height: 1px;
    background-color: #F1F1F1;
}