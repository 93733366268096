.product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
}

.product-top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40vh;
}

.product-top-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
}

.product-top-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 60px;
    font-weight: 700;
    color: #424242;
    margin-left: 120px;
    text-align: left;
}

.product-top-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 100%;
    position: relative;
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    z-index: 0;
}

.product-top-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.product-mid-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 83%;
    margin-top: 30px;
    overflow: hidden;
    margin-bottom: 30px;
}

.product-category-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: #424242;
    text-align: left;
    margin-bottom: 20px;

}

.product-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-gap: 20px;
}


.product-card {
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.3s;

}

.product-card:hover {
    transform: scale(1.05);
    z-index: 1;
}

.product-card-image {
    /* width: 100%;
    height: auto; */
    width: 360px;
    height: 300px;
    background-color: #333333;

}

.product-card-details {
    padding: 10px;
    background-color: #fff;
}

.product-card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.product-card-description {
    font-size: 14px;
    color: #777;
}

@media screen and (max-width: 768px) {
    .product-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .product-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 20px;
    }

    .product-top-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .product-top-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 1;
    }

    .product-top-right {

        width: 100%;
        clip-path: polygon(00% 0%, 100% 0%, 100% 80%, 0% 100%);

    }

    .product-top-heading {
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-weight: 700;
        color: #424242;
        margin-left: 0;
        text-align: center;
    }

    .product-category-heading {
        font-family: 'Roboto', sans-serif;
        font-size: 25px;
        font-weight: 600;
        color: #424242;
        text-align: center;
        margin-bottom: 20px;

    }
}




.bold {
    font-weight: bold;
    color: #424242;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}