html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  background-color: white;
}